.homepage {
  display: flex;
  flex-direction: column;
  width: 97%;
  height: 100%;
  padding: 20px;
  gap: 24px;

  background-color: #f8f9fa;
}

.welcome-sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-wrap: balance;
  width: 100%;
  height: 133px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}
.welcome-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0px 16px;
  align-items: start;
  /* z-index: 1001; */
}
.welcome-p2 {
  color: #757575;
  font-size: 14px;
}

.circle-head {
  display: flex;
  justify-content: end;
  border-radius: 10px;
  width: 60%;
  /* margin-right: -173px; */
  height: 100%;
  /* z-index: 1000; */
}
.chart-over {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 387px;
  gap: 12px;
}

.chart-bar-over {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* width: 362px; */
  width: 33%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}

.table-sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 445px;
}

.table-sort {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 622px;
  height: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}

.chart-bar-full {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}

.sec-info-chart {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  justify-content: start;
}

.sec-chart-area {
  margin: 10px;
  width: 100%;
  /* height: 100%; */
}
.table-sort {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sec-info-area {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  bottom: 20px;
}

.drop-store {
  margin-right: 16px;
  font-size: 12px;
  width: 33%;
  z-index: 1000;
}
.css-13cymwt-control {
  border-radius: 10px !important;
}

.css-t3ipsp-control {
  border-radius: 10px !important;
}
.css-1nmfiq5-menu {
  border-radius: 10px !important;
  background-color: transparent;
}

.css-b62m3t-container {
  width: 90%;
}

.icon-det {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: #000000;
  border-radius: 10px;
  margin-left: 8px;
}
.bi-card-checklist,
.bi-list-check {
  color: #ffffff;
}

.sec-detail {
  width: 40%;
  height: 93%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  text-wrap: nowrap;
  margin-left: 32px;
}

.sec-detail-1,
.sec-detail-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  width: 40%;
}

.sec-detail-1,
.sec-detail-2 {
  color: #757575;
}

.info-data {
  display: flex;
  gap: 10px;
}
.loading {
  background-color: #f0f0f0;
  width: 100%;
  height: 3px;
  direction: ltr;
  border-radius: 10px;
}
#loading-info-1,
#loading-info-2 {
  height: 100%;
  background-color: #141414;
  /* width: 50%; */
  border-radius: 10px;
}

.yourClassName {
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 2px 8px;
  color: red;
  background: rgb(255, 242, 242);
  width: fit-content;
  border-radius: 4px;
  direction: ltr;
  align-items: center;
}

.info-sec {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  gap: 8px;
}

.info-sec p {
  display: inline-block;
}

.arrow_box_s {
  background-color: #eaebec;
  padding-right: 10px;
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
}
.arrow_box_data_s {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-right: 10px;
  height: 70%;
  width: 100%;
}
.arrow_box_main_s {
  width: 150px;
  height: 120px;
}

.totalchart {
  margin-right: 25px;
  margin-top: 22px;
}


@media (max-width: 768px){
  .homepage{
    padding: 8px;
    width: 95%;
  }
  .circle-head{
    display: none;
  }
  .chart-over{
    flex-direction: column;
    height: auto;
  }
  .chart-bar-over{
    width: 100%;
    padding: 8px 0;
  }
  .table-sec{
    flex-direction: column;
    height: auto;
  }
  .chart-bar-full{
    width: 100%;
  }
  .sec-info-area{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  .sec-detail{
    width: 100%;
    margin: 0;
    justify-content: space-evenly;
  } 
  .drop-store{
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .table-container{
    width: 100%;
  }
  .welcome-info{
    align-items: center;
  }
  .welcome-sec {
    text-align: center;
  }
}
