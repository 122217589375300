.main-viwe-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sec-view {
  width: 95%;
  height: 420px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: start;
}

.sec-stores {
  width: 25%;
  height: 497px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sec-info-stores {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  margin: 8px;
}
.sec-info-stores h3 {
  font-weight: 200;
}
.line {
  /* margin-top: 5px; */
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-image: linear-gradient(
      to left,
      rgb(255, 255, 255),
      rgb(165, 164, 164),
      rgba(0, 0, 0, 0)
    )
    100% 1;
}

.childs-stores {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.child-right-info-stores {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.child-right-info-stores p {
  color: #8d9092;
}

.child-left-info-stores {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.child-left-info-stores p {
  font-weight: 300;
}
.sec-scroll-stores {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  overflow-x: auto;
  padding-bottom: 78px;
}
.sec-cart {
  width: 100%;
  height: 78px;
  display: flex;
  cursor: pointer;

  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.child-cart-top {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}
.child-cart-bottom {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.child-b-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
  gap: 5px;
}
.child-b-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 14px;
  gap: 5px;
}
.child-cart-left {
  width: 60px;
  height: 25px;
  background-color: black;
  border-radius: 6px;
  margin-left: 14px;
}
.child-cart-left p {
  text-align: center;
  color: #ffffff;
}

.child-cart-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-right: 14px;
}
.icon-sort-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-left: 8px;
  box-shadow: 0px 7px 10px -2px rgba(250, 113, 0, 0.4),
    0px 3px 4px -1.5px rgba(0, 0, 0, 0.05);
}

.sec-detail-view {
  width: 799px;
  height: 406px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}

/*  */

.table-container-v {
  width: 100% !important;
  height: 100% !important;
  align-items: start !important;
}

.filter-select-v {
  width: 72.3% !important;
  height: 20% !important;
}
/*  */

.main-p {
  width: 73%;
  height: 406px;
  background-color: white;
  border-radius: 10px;
  /*استایل های فلکس*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}

.main-header-p {
  /* این چهار خط برا تسته جای دیواس*/
  width: 95%;
  height: 40px;
  /* border: 1px solid rgb(190, 190, 190); */
  border-radius: 8px;
  /*---------*/
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.notif-header-p {
  width: 105px;
  height: 40px;
  background-color: #f9f9f9;
  border: 1px solid #ededed;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tex-notif-p {
  margin-bottom: 8px;
  margin-top: 8px;
  font-size: 14px;
  line-height: 24%;
  font-style: normal;
  font-weight: 600;
}
.title-header-p {
  font-size: 20px;
  line-height: 140%;
  font-style: normal;
  font-weight: normal;
}
.main-firsSection-p {
  /* این چهار خط برا تسته جای دیواس*/
  width: 96%;
  height: 29.56%;
  /* border: 1px solid rgb(190, 190, 190); */
  border-radius: 8px;
  /*---------*/
  background-color: #f4f4f4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}
.firstSection-button-p {
  cursor: pointer;
  width: 45.76%;
  height: 73.33%;
  /* border: 1px solid rgb(190, 190, 190); */
  background-color: #f4f4f4;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 0.5s ease-out;
}
/* .main-firsSection-p :hover {
  background-color: white;
  box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.05),
    4px 20px 27px rgba(0, 0, 0, 0.05);
} */
.active-p {
  background-color: white;
  box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.05),
    4px 20px 27px rgba(0, 0, 0, 0.05);
}
.active-p .icon-firstButton-p {
  background-color: #006fee;
}
.active-p .icon-firstButton-p path {
  color: rgb(255, 255, 255);
}
.icon-firstButton-p path {
  color: #006fee;
}
/* .seeddddddd{
  color:"#006FEE" 
} */
.icon-firstButton-p {
  background-color: #ffffff;
  width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 7px;
  padding: 2.5px 2.5px 2.5px 3px;
  box-shadow: 0px 10px 15px rgba(0, 111, 238, 0.04),
    0px 4px 6px rgba(0, 0, 0, 0.08);
  margin-right: 16px;
  margin-top: 16px;
}
.title-firstButton-p {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12px;
  margin-right: 16px;
}
.title-firstButton-p h6 {
  color: #67748e;
}
.title-firstButton-p p {
  font-size: 20px;
  line-height: 180%;
  font-style: normal;
  font-weight: 300;
}
.title-firstButton-num-p {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 2px;
}
.percent-firstButton-p p {
  font-size: 12px;
  line-height: 180%;
  font-style: normal;
  font-weight: 700;
  color: red;
}
.percent-firstButton-p {
  /* margin-right: 168px; */
  margin-top: 10px;
  margin-left: 10px;
}

/* .saeeddd {
  
  width: 100%;
}
.saeeddd div{
  
  transition: all 0.3s ease-out;
} */
.tab {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.tab-hide {
  opacity: 0;
}
.tabs-content {
  width: 100%;
}

/* 
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
} */
.table-s {
  height: 456px;
  width: 94%;
}
.firstButton-s {
  width: 80%;
  display: flex;
}

.sec-cart.selected {
  background-color: black;
  color: #ffffff;
  border-radius: 10px;
  cursor: auto;
}

.selected-background {
  background-color: white;
}

.selected-background .caption-lg {
  color: black;
}

@media (max-width: 768px) {
  .sec-view {
    flex-direction: column-reverse;
    height: auto;
    gap: 16px;
  }
  .sec-stores,
  .main-p {
    width: 100%;
    height: auto;
  }

  .main-firsSection-p p {
    font-size: 14px !important;
  }
  .firstSection-button-p {
    height: auto;
    margin: 10px 0;
  }
  .firstButton-s {
    flex-direction: column;
  }
  .tab {
    padding: 16px;
  }
  .main-header-p {
    margin-bottom: 8px;
  }
  .table-s {
    margin-top: 16px;
  }
  .filter-select-v {
    width: 95% !important;
    height: auto !important;
  }
}
