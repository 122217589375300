@import url(fontiran.css);
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css);
body {
  font-family: ModamFaNumWeb !important;
  direction: rtl;
  margin: 0;
}

/* * {
  scrollbar-width: none;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: ModamFaNumWeb !important;
}

/* .wrapper {
  max-width: 900px;
  margin: 20px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px #aaa;
} */

.box100 p,
.box200 p,
.box300 p,
.box400 p,
.box500 p,
.box600 p,
.box700 p,
.box800 p,
.box900 p {
  font-size: 20px;
  margin: 0;
}

.box100,
.box100 h1,
.box200,
.box200 h1,
.box300,
.box300 h1,
.box400,
.box400 h1,
.box500,
.box500 h1,
.box600,
.box600 h1,
.box700,
.box700 h1,
.box800,
.box800 h1,
.box900,
.box900 h1 {
  margin: 30px 0 0 0;
  font-size: 25px;
}

.box100,
.box100 h1 {
  font-weight: 100;
}

.box200,
.box200 h1 {
  font-weight: 200;
}

.box300,
.box300 h1 {
  font-weight: 300;
}

.box400,
.box400 h1 {
  font-weight: 400;
}

.box500,
.box500 h1 {
  font-weight: 500;
}

.box600,
.box600 h1 {
  font-weight: 600;
}

.box700,
.box700 h1 {
  font-weight: 700;
}

.box800,
.box800 h1 {
  font-weight: 800;
}

.box900,
.box900 h1 {
  font-weight: 900;
}

/*  */

button {
  font-family: "ModamFaNumWeb";
}

a {
  text-decoration: none;
}

h1 {
  font-size: 44px;
  line-height: 140%;
  font-style: normal;
  font-weight: normal;
}

h2 {
  font-size: 40px;
  line-height: 140%;
  font-style: normal;
  font-weight: normal;
}
h3 {
  font-size: 32px;
  line-height: 140%;
  font-style: normal;
  font-weight: normal;
}

h4 {
  font-size: 24px;
  line-height: 140%;
  font-style: normal;
  font-weight: normal;
}
h5 {
  font-size: 20px;
  line-height: 140%;
  font-style: normal;
  font-weight: normal;
}
h6 {
  font-size: 16px;
  line-height: 140%;
  font-style: normal;
  font-weight: normal;
}

.Body-XL {
  font-size: 20px;
  line-height: 180%;
  font-style: normal;
  font-weight: 300;
}

.Body-LG {
  font-size: 18px;
  line-height: 180%;
  font-style: normal;
  font-weight: 300;
}

.Body-MD {
  font-size: 16px;
  line-height: 180%;
  font-style: normal;
  font-weight: normal;
}

.Body-SM {
  font-size: 14px;
  line-height: 180%;
  font-style: normal;
  font-weight: 600;
}

.caption-lg {
  font-size: 14px;
  line-height: 180%;
  font-style: normal;
  font-weight: normal;
}

.caption-md {
  font-size: 12px;
  line-height: 180%;
  font-style: normal;
  font-weight: normal;
}
.caption-sm {
  font-size: 10px;
  line-height: 180%;
  font-style: normal;
  font-weight: bold;
}

.Button-LG {
  font-size: 16px;
  line-height: 180%;
  font-style: normal;
  font-weight: 500;
}

.Button-SM {
  font-size: 14px;
  line-height: 24%;
  font-style: normal;
  font-weight: 500;
}
