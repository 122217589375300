/* @import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css);
@font-face {
  font-family: "IRANSansXFaNum-Medium";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/64531e42e59a1c70301cde31/fonts/iransansweb(fanum)_medium.woff2")
    format("");
}
@font-face {
  font-family: "IRANSansXFaNum-Bold";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/64fef55fd2b751dfe8287532/fonts/iransansxfanum-bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "IRANSansXFaNum-DemiBold";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/64fef55fd2b751dfe8287532/fonts/iransansxfanum-demibold.ttf")
    format("truetype");
}

@font-face {
  font-family: "IRANSansXFaNum-Regular";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/64a4027c87ad57d946f19ba7/fonts/iransansx-medium.woff2")
    format("");
} */

.fa-2x {
  font-size: 2em;
}
.fa {
  /* position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle; */
  font-size: 20px;
}
button {
  /*-----------------------------------------------------------------------*/
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background-color: #1698fc;
  border: 0px;
  color: #fff;
  /* font-family: IRANSansXFaNum; */
  cursor: pointer;
}
.bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: initial;
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 10px;
}
/* .tbl-header {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
} */
.tbl-content {
  margin-top: 15px;
  height: 90vh;
  overflow-x: auto;
  overflow-y: auto;

  /* border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px; */
}
.f-glass {
  /* background: rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.18); */
  /* background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.18),
    rgba(255, 255, 255, 0.18),
    #a4a9ff25
  );
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 2z55, 255, 0.17); */
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  padding-left: 25px;
  padding-right: 25px;
  height: 95%;
}
th {
  color: #000;
  text-align: center;
  /* font-family: IRANSansXFaNum; */
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 180%; /* 28.8px */
  text-transform: capitalize;
}
td {
  padding: 20px 15px;
  text-align: center;
  vertical-align: middle;
  /* font-family: IRANSansXFaNum; */
  font-weight: 300;
  font-size: 15px;
  color: #000000;
  /* padding:11px; */
  background-color: #f9f9f9;
  /* border-bottom: solid 2px rgba(255, 255, 255, 0.1);
  border-radius: 15px; */
}
td:last-child {
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
}

td:first-child {
  border-bottom-right-radius: 27px;
  border-top-right-radius: 27px;
}
/* tr {
  border: solid 2px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
} */
/* tr {
  border: 0;
  display: block;
  margin: 5px;
  border: 2px red solid;
  background: rgba(255, 255, 255, 0.192);
  border-radius: 10px;
} */

@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);
body {
  background: #f8f9fa;
  /* background: -webkit-linear-gradient(top, #0f0c29, #302b63, #24243e); */
  /* font-family: Vazir; */
  direction: rtl;
  /*overflow: hidden;*/
  font-family: ModamFaNumWeb !important;
}
/* section {
  margin: 50px;
} */

/* @font-face {
  font-family: "Vazir";
  font-style: normal;
  font-weight: 300;
  src: url(../font/Vazir.woff) format("woff");
}
@font-face {
  font-family: "IRANSansWeb";
  font-style: normal;
  font-weight: 300;
  src: url(../font/IRANSansWeb_Light.woff) format("woff");
} */

/* * {
  box-sizing: border-box;
} */

/* Column container */
.row {
  display: flex;
  /* flex-wrap: wrap; */
}

/* Create two unequal columns that sits next to each other */
/* Sidebar/left column */
.side {
  /*flex: 40%;*/
  /* background-color: #f1f1f1; */
  padding: 5px;
  width: 40%;
}

/* Main column */
.main {
  /*flex: 60%;*/
  /* background-color: white; */
  padding: 5px;
  width: 60%;
}

/* Fake image, just for this example */
.fakeimg {
  /* background-color: #aaa;
  width: 100%;
  padding: 20px; */
  margin: 10px;
  /* background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.18),
    rgba(255, 255, 255, 0.18),
    #a4a9ff25
  ); */
  /* border-radius: 16px; */
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  /* border: 1px solid rgba(255, 255, 255, 0.17); */

  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-evenly;
}
.fakeimg2 {
  /* background-color: #aaa;
  width: 100%;
  padding: 20px; */
  /*margin: 10px;*/
  /* background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.18),
    rgba(255, 255, 255, 0.18),
    #a4a9ff25
  ); */
  /* border-radius: 16px; */
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  /* border: 1px solid rgba(255, 255, 255, 0.17); */

  /*backdrop-filter: blur(7px);*/
  /*-webkit-backdrop-filter: blur(7px);*/
  /*border-radius: 12px;*/
  /*background: #fff;*/
  /*box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);*/
  display: flex;
  justify-content: space-evenly;
}
/* img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(50%);
  margin: 20px;
} */

#flex-tarikh {
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  margin-left: 40px;
  margin-top: 10px;
  color: #000;
  text-align: right;
  /* font-family: IRANSansXFaNum; */
  font-style: normal;
  font-weight: 300;
  line-height: 180%; /* 28.8px */
  text-transform: capitalize;
}

#flex-sat {
  display: flex;
  justify-content: end;
  margin-left: 40px;
  font-size: 40px;
  font-weight: bold;
  color: #000;
  text-align: right;
  /* font-family: IRANSansXFaNum; */
  font-style: normal;
  font-weight: 300;
  line-height: 180%; /* 28.8px */
  text-transform: capitalize;
}

#qeimat-kol {
  color: #000;
  text-align: right;
  /* font-family: IRANSansXFaNum; */
  font-size: 20px;
  font-style: Bold;
  font-weight: 300;
  line-height: 180%; /* 28.8px */
  text-transform: capitalize;
  margin-top: 10px;
}
#qeimat-text {
  color: #000;
  text-align: right;
  /* font-family: IRANSansXFaNum; */
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 180%; /* 28.8px */
  text-transform: capitalize;
  margin-top: 10px;
}

/* #loader {
  border: 3px solid #052c25;
  width: 500px;
  height: 15px;
  background: linear-gradient(to right, #89d5c7 50%, transparent 0, #052c25 1%);
  border-radius: 10px;
  background-clip: padding-box;
} */
#loader {
  border: 0px solid #052c25;
  position: relative;
  width: 100%;
  margin: 0px 20px;
  height: 12px;
  background: #cbcbcb;
  border-radius: 10px;
}

#loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* width: 15.5%; */
  height: 100%;
  background-color: #1698fc;
  border-radius: 10px;
}

.wrapper {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #1698fc;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.wrapper span {
  position: absolute;
  top: 75px;
  /* font-family: "Lato"; */
  font-size: 20px;
  letter-spacing: 12px;
  color: #fff;
  left: 15%;
}

/* #scrollableDiv {
  max-height: 300px;
  overflow-y: auto;
} */

.dokme {
  /* font-family: "IRANSansWeb"; */
  border-radius: 20px;
  height: 40px;
  width: 80px;
}
.dokmeha {
  display: flex;
  justify-content: space-between;
  margin: 8px;
  padding: 10px;
}
#qeimat {
  color: #000;
  /* font-family: IRANSansXFaNum; */
  font-style: normal;

  font-size: 50px;
}
#day {
  font-size: 40px;
}
#tarikh {
  font-size: 28px;
}
.div-discount {
  background: #89d5c7;
  color: #052c25;
  border-radius: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media screen and (max-width: 960px) {
  /* کلاس side را به تمام عرض تغییر دهید */
  .side {
    width: 100%;
    padding: 0px;
    height: 665px;
  }
  .row {
    flex-direction: column-reverse;
  }
  /* کلاس main را به تمام عرض تغییر دهید و از side پایین قرار بگیرد */
  .main {
    width: 100%;
    padding: 10px;
    /*margin-top: 10px; !* یا مقدار مورد نظر شما *!*/
  }

  /* اندازه فونت‌ها را کوچکتر کنید */
  body {
    font-size: 14px;
  }

  th,
  td {
    font-size: 12px;
    padding: 10px 18px;
  }

  #qeimat {
    font-size: 25px;
  }
  #day {
    font-size: 15px;
  }
  #tarikh {
    font-size: 14px;
  }
  #flex-sat {
    font-size: 14px;
  }
  .fa {
    font-size: 15px;
  }

  .div-discount {
    padding: 2px 5px;
  }

  .f-glass {
    padding-left: 5px;
    padding-right: 5px;
  }

  /* img {
    width: 100px;
    height: 100px;
  } */
  .imgreader2 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  table {
    width: 100%;
  }

  table thead {
    display: none;
  }

  table tr,
  table td {
    border-bottom: 1px solid #ededed;
  }

  table tr {
    margin-bottom: 8px;
  }

  table td {
    display: flex;
    text-align: start;
    justify-content: space-between;
  }

  table td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
  }

  td:first-child {
    border-bottom-right-radius: 0px;
    /*border-top-right-radius: 0px;*/
    border-top-left-radius: 27px;
  }

  td:last-child {
    /*border-bottom-left-radius: 0px;*/
    border-bottom-right-radius: 27px;
    border-top-left-radius: 0px;
  }
}

.img-reader2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-reader img {
  width: 100%;
}
.custom-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.layer-back,
.layer-middle,
.layer-top {
  background-color: transparent;
  height: auto;
}

.layer-back {
  position: fixed;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #cbcbcb;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  width: auto;
  height: auto;
  border-radius: 24px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  margin: 0px;
}

.layer-middle {
  /*position: fixed;*/
  /*top: 0;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  background-color: #f9f9f9;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  width: auto;
  height: auto;
  border-radius: 24px;
  z-index: 1;
  padding-bottom: 30px;
  margin-right: -18px;
  margin-left: -18px;
}

.layer-top {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  width: auto;
  height: auto;
  border-radius: 24px;
  z-index: 3;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  background-color: #ffffff;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  margin-right: -26px;
  margin-left: -26px;
}

.list {
  margin: 20px 0;
}
.list p {
  margin-right: 15px;
}
.list ul {
  display: flex;
  justify-content: right;
  /* position: relative; */
  /* font-family: IRANSansXFaNum; */
  /*font-size: 18px;*/
  margin-top: 15px;
  margin-right: 15px;
  list-style-type: none;
  padding: 0;
  text-align: center;
}

/*.img-reader {*/
/*  margin-right: 22px;*/
/*}*/
.buttons {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-top: 14px;
  padding-right: 10px;
}
.formModal {
  width: 100%;
}

.formInput {
  margin-bottom: 10px;
  margin-right: 16px;
}

.icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #1698fc;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex-direction: row-reverse;
}

.icon-button svg {
  margin-right: 5px;
}

.logoutButton {
  color: #000;
  background-color: #fff;
}
.custom-dropdown {
  /* font-family: IRANSansXFaNum; */
  background-color: #fff;
  border-radius: 10px;
  width: auto;
  height: auto;
  padding: 13px 28px;
  margin-left: 16px;
  margin-right: 10px;
  border: 1px solid #48484836;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}
/*//////////////////////////////////////////////*/
.custom-option {
  background-color: #ffffff;
}

.custom-option:focus {
  background-color: #7f8177;
}
/*//////////////////////////////////////////////*/

.drop2 {
  display: flex;
  border-radius: 14px;
  flex-wrap: wrap;
  margin: 18px;
  gap: 8px;
  /* font-family: IRANSansXFaNum; */
}

.icon-text-container {
  /* font-family: IRANSansXFaNum; */
  display: inline-flex;
  align-items: center;
}

.icon-text-container img {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: 20px;
}

.bold {
  color: #1698fc;
}

.custom-dropdown-Report {
  /* font-family: IRANSansXFaNum; */
  background-color: #fff;
  border-radius: 14px;
  width: auto;
  height: auto;
  padding: 13px 28px;
  margin-left: 16px;
  margin-right: 5px;
  border: 1px solid #48484836;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}
.report {
  display: flex;
  /*gap: 30px;*/
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.report-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  /*.layer-top {*/
  /*  width: 60%;*/
  /*}*/
  /*.layer-middle {*/
  /*  width: 57%;*/
  /*}*/
  .layer-back {
    width: 70%;
  }
  .buttons {
    flex-direction: column;
    padding-right: 0px !important;
  }
  .input-reader {
    width: 90% !important;
  }
  .DatePicker {
    flex-direction: column;
  }
  .digi {
    display: none !important;
  }
  .calender {
    font-size: 12px;
  }
  .column-background {
    padding-right: 3px !important;
    padding-left: 2px !important;
  }
  .header-4 {
    flex-direction: column;
    gap: 16px;
  }
  .header-4-b {
    width: 100% !important;
  }
}

.div-chart {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0px;
}

.clock {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  /* position: absolute; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  color: #444;
  text-align: center;
  margin-top: 300px;
  margin-left: 300px;
}

.clock::after {
  background: #aaa;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
}

.hour_hand {
  position: absolute;
  width: 6px;
  height: 60px;
  background: #222;
  top: 30%;
  left: 49%;
  transform-origin: bottom;
}

.min_hand {
  position: absolute;
  width: 4px;
  height: 80px;
  background: #444;
  top: 22.5%;
  left: 49%;
  transform-origin: bottom;
}

.sec_hand {
  position: absolute;
  width: 2px;
  height: 118px;
  background: red;
  top: 10.5%;
  left: 50%;
  transform-origin: bottom;
}

.clock span {
  position: absolute;
  /* font-family: "Source Sans Pro", sans-serif; */
  font-weight: 700;
}

.twelve {
  top: 10px;
  left: 46%;
}

.one {
  top: 10%;
  right: 26%;
}

.eleven {
  top: 10%;
  left: 26%;
}

.two {
  top: 25%;
  right: 10%;
}

.three {
  right: 10px;
  top: 46%;
}

.four {
  right: 30px;
  top: 67%;
}

.five {
  right: 78px;
  top: 80%;
}

.six {
  bottom: 10px;
  left: 50%;
}

.seven {
  left: 80px;
  top: 82%;
}

.eight {
  left: 30px;
  top: 67%;
}

.nine {
  left: 10px;
  top: 46%;
}

.ten {
  top: 25%;
  left: 10%;
}

/* .sectionTime{
  height: 112px;
  width: 185px;
  border-radius: 12px;
  background-color:#ededed ;
  position:relative;
  left: 400px;
  top: 300px;
  display: flex;
  flex-direction: row;
} */

.dodol {
  display: flex;
}

.sectionTime,
.calender {
  flex: 1;
  padding: 20px;
}

.sectionTime {
  display: flex;
  margin-right: 10px; /* فاصله بین دو بخش */
}

.week-calendar {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.day-column {
  flex: 1;
  text-align: center;
}

.date {
  font-weight: bold;
  padding-top: 16px;
}
.day {
  /* padding-top: 4px; */
}

.clock {
  flex: 1;
}
.column-background {
  width: 21px;
  background-color: #1698fc;
  border-radius: 20px;
  padding-right: 7px;
  padding-left: 6px;
  padding-bottom: 4px;
  color: #fff;
}
.day-column1 {
  text-align: center;
}

.digi {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.digital-clocks {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.DatePicker {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 10px;
  /*margin-bottom: 10px;*/
}

.rmdp-container {
  width: 33%;
}
.rmdp-input {
  width: 90%;
  border-radius: 10px !important;
  text-align: center;
  padding: 4px 5px !important;
  /* font-family: "IRANSansXFaNum"; */
}

/*.clock-time{*/
/*  padding-right: 12px;*/
/*  display: flex;*/
/*  justify-content: start;*/
/*  align-items: center;*/
/*  gap: 8px;*/
/*  font-size: 14px;*/
/*  color: #67748E;*/

/*}*/
/*#chart{*/
/*height: 74%;*/
/*padding: 10px;*/
/*}*/

.item-div p {
  color: #67748e;
}
.item-div button {
  cursor: auto !important;

  padding: 8px 8px !important;
  /*border-radius: 7px;*/
}

.DatePicker-div {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.time-check {
  display: flex;
  gap: 5px;
  width: 100%;
}
.clock-time {
  padding-right: 12px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #67748e;
}

.time-check button {
  background-color: #cbcbcb;
  color: #757575;
  padding: 6px 10px !important;
  cursor: no-drop !important;
}
.time-check-button1 {
  width: 19%;
}
.time-check-button2 {
  width: 23%;
}
.time-check-button3 {
  width: 30%;
}
.analogclock-flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.drop2 div {
  width: auto !important;
}

.header-4-b {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
}
.header-4 {
  padding: 12px !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-bottom: 0 !important;
}
