.not-found-container {
  display: flex;

  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* .not-found-content {
  text-align: center;
} */
