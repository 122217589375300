/* .footer {
  background-color: #ff0000;
  color: white;
  padding: 20px;
  text-align: center;
} */

.footer {
  margin-top: auto; /* This pushes the footer to the bottom */
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
  height: 40px;
  z-index: 2;
  align-items: center;
}

.footer .footer-text {
  margin-right: 25px;
  color: #67748e;
}

.footer .footer-list {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 3rem;
  list-style-type: none;
}
.nav_link-footer {
  text-decoration: none;
  color: #014274;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column-reverse;
    margin-top: 16px;
  }
  .footer .footer-list,
  .footer .footer-text {
    margin: 0;
  }
  .footer .footer-list {
    padding-bottom: 8px;
  }
}
