.container {
  /* margin: 20px; */
  padding: 20px;
  background-color: #f0f0f0;
}
.price {
  color: green;
}
.live{
  display: flex;
}
.live-keys{
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid red;
}
.live-data{
  border-bottom: 1px solid blue;
  display: flex;
  flex-direction: column;
}

.live-ok{
  display: flex;
  justify-content: space-between;
  padding: 0px 14px;
}