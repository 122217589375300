.outlet {
  display: flex;
  flex-direction: column;
  width: 80%;
  overflow-x: hidden;
}
.menu-outlet {
  display: flex;
}
@media (max-width: 768px) {
  .outlet {
    width: 100%;
    margin-bottom: 64px;
  }
}
