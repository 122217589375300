.table-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  align-items: center;
  width: 50%;
  height: 100%;
}

.table-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
}

.table-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
}

.table-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.sec-table {
  width: 100%;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.sec-table th,
.sec-table td {
  padding: 20px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px !important;
  background-color: transparent !important ;
}

.sec-table th {
  color: #67748e;
}

.sec-table thead {
  background-color: transparent;
}

.sec-table tr:nth-child(even) {
  background-color: transparent;
}

.sec-table-wrapper {
  width: 100%;
  /* max-height: 300px; */
  height: 90%;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}

.sec-table td:last-child {
  border-right: none;
}

.sec-table thead {
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.filter-select {
  width: 97%;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  padding: 0px 8px;
}

.filter-select select {
  /* width: calc((100% - 40px) / 3); */
  font-family: ModamFaNumWeb;
  /* padding: 10px; */
  border: 2px solid #ced4da;
  font-size: 12px;
  border-bottom: none;
  border-top: none;
  border-right: none;
  height: 100%;
  background-color: #fff;
}

.filter-select button {
  color: #e61010;
  border: none;
  border-radius: 0px;
  font-size: 12px;
  cursor: pointer;
  border: 2px solid #ced4da;
  border-bottom: none;
  border-top: none;
  border-left: none;
  height: 100%;
  background-color: transparent;
  text-wrap: nowrap;
  padding: 0px 8px;
}

.icon-filter-1 {
  color: #007bff;
  height: 100%;
  display: flex;
  align-items: center;
  border: 2px solid #ced4da;
  border-bottom: none;
  border-top: none;
  border-right: none;
  width: 15%;
  justify-content: center;
}

@media (max-width: 768px) {
  .sec-table tr {
    display: block;
    margin-bottom: 10px;
    border: none;
  }
  .sec-table th,
  .sec-table td {
    background-color: #f9f9f9 !important;
  }
  td:first-child {
    border-top-left-radius: 27px !important;
  }

  td:first-child {
    border-top-right-radius: 27px !important;
  }
  td:last-child {
    border-bottom-right-radius: 27px !important;
  }
  td:last-child {
    border-bottom-left-radius: 27px !important;
    border: none;
  }
  .filter-select {
    flex-direction: column;
    height: auto;
    gap: 16px;
    padding: 8px;
  }
  .filter-select select {
    border: none;
    width: 100%;
  }
  .icon-filter-1 {
    border: none;
    width: 100%;
  }
  .filter-select button {
    border: none;
    width: 100%;
  }
  .rmdp-container {
    width: 100%;
  }
}
