* {
  padding: 0;
  margin: 0;
}

/* @font-face {
  font-family: "IRANSansXFaNum-Bold";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/64fef55fd2b751dfe8287532/fonts/iransansxfanum-bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "IRANSansXFaNum-DemiBold";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/64fef55fd2b751dfe8287532/fonts/iransansxfanum-demibold.ttf")
    format("truetype");
}

@font-face {
  font-family: "IRANSansXFaNum-Regular";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/64a4027c87ad57d946f19ba7/fonts/iransansx-medium.woff2")
    format("");
}

@font-face {
  font-family: "IRANSansXFaNum-Medium";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/64531e42e59a1c70301cde31/fonts/iransansweb(fanum)_medium.woff2")
    format("");
} */

/* * HEADER * */

.loginForm .loginPageHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 24px;
  z-index: 2;
}

.loginForm header a:hover {
  color: #1698fc;
  font-weight: bold;
}

.loginForm .headerBtn {
  /* font-family: "IRANSansXFaNum-Bold"; */
  background-color: #1698fc;
  /* width: 103px; */
  height: 40px;
  padding: 8px, 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.loginForm .loginLogo {
  width: 64px;
  height: 27.78px;
  padding-left: 30px;
}

.loginForm .loginNav {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(11px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* font-family: "IRANSansXFaNum-Regular"; */
  font-size: 14px;
  width: 75%;
  height: 70px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin: 24px auto 0 auto; */

  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.2);
}

.loginForm .loginList {
  list-style-type: none;
  /* background: rgba(255, 255, 255, 0.55); */
  /*backdrop-filter: blur(11px);*/
  -webkit-backdrop-filter: blur(11px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.loginForm .loginItems {
  margin-right: 20px;
  font-size: 20px;
  text-transform: uppercase;
  color: #f1f1f1;
  cursor: pointer;
}

.loginForm .loginPageBtn {
  position: absolute;
  display: none;
  right: 10px;
  top: 7px;
  padding: 5px;
  color: #000;
  font-size: 18px;
  width: 3.2rem;
  padding-top: 0.5rem;
}

/* @media screen and (max-width: 768px) {
  .loginNav {
    display: block;
  }

  .loginForm .loginLogo {
    position: absolute;
    right: 25%;
    top: 20%;
  }

  .headerBtn {
    position: absolute;
    right: 50%;
    top: 20%;
  }

  .loginList {
    flex-direction: column;
    height: auto;
    margin-top: 4.7rem;
  }
  .loginItems:nth-child(1) {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    margin-top: 50px;
  }
  .loginItems {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    text-align: right;
    margin-right: 0px;
    padding: 30px 0;
  }

  .loginPageBtn {
    display: block;
    border: none;
    top: 20%;
  }
  
} */

.loginForm ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
}

.loginForm .loginNav a {
  /* font-family: "IRANSansXFaNum-Bold"; */
  text-decoration: none;
  padding: 1.6rem;
  font-size: 16px;
  font-weight: 600;
  color: black;
  transition: 0.3 ease;
}

.loginForm .loginNav a:hover {
  /* font-family: "IRANSansXFaNum-Bold"; */
  color: #1698fc;
  font-weight: bold;
}

.loginForm .active {
  /* font-family: "IRANSansXFaNum-Bold"; */
  color: #38bdf8;
  font-weight: bold;
}

/* LOGIN FORM  */

.loginForm h3 {
  color: #1698fc;
  padding: 0rem 0;
  font-size: 32px;
  /* font-family: "IRANSansXFaNum-Bold"; */
}

.loginForm .formBody {
  /* font-family: "IRANSansXFaNum-Bold"; */
  /* margin-right: 184px;*/
  margin-top: 135px;
  display: flex;
  justify-content: center;
}

.loginForm .form-item {
  padding: 1.5rem 0;
  padding-top: 16px;
}
/* /////////////////////////////////////////////////////////////////////////////////// */
.loginForm .background-image {
  position: fixed; /* برای قرار دادن پس‌زمینه */
  left: 0%;
  top: 0%;
  width: 100%; /* (1602.79px / 1000px) * 100 */
  height: 100%;
  background-image: url("../assets/img/Asset 2 3 (1).svg");
  background-size: cover; /* پوشش دادن کامل تصویر */
  /* background-position: center;  */
  background-repeat: no-repeat; /* عدم تکرار تصویر */
  /*transform: rotate(337.4deg); !* چرخاندن با زاویه 20 درجه *!*/
  z-index: -1;
}

.loginForm .error {
  color: #c30000;
  /* position: absolute;
  top: 55px;
  right: 10px; */
  font-size: 12px;
}

.loginForm .red-border {
  border: 3px solid #c30000;
}

.loginForm .form-btn {
  background-color: #1698fc;
  /* font-family: "IRANSansXFaNum-Bold"; */
  color: #ffffff;
  width: 324px;
  height: 40px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
}

.loginForm .form-btn:hover {
  background-color: #63bafd;
  /* font-family: "IRANSansXFaNum-Bold"; */
  color: #ffffff;
  width: 324px;
  height: 40px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
}

.loginForm .disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.loginForm .input-wrapper {
  --padding: 16px;
  --focus-color: #1698fc;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.loginForm input {
  background-color: #f8f9fa;
  width: 308px;
  height: 40px;
  border: 3px solid gray;
  color: #070707;
  display: block;
  outline: none;
  border-radius: 1rem;
  /* padding-left: 48px; افزودن فضای لازم برای آیکون */
  padding-right: 1rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 180%; /* 21.6px */
}

.loginForm .placeholder {
  position: absolute;
  background-color: #fff;
  right: var(--padding);
  padding: 0 4px;
  transition: all 0.2s ease-in;
  /*top: -5;*/
  color: var(--070707, #070707);
  text-align: right;
  /* font-family: IRANSansXFaNum; */
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 300;
  line-height: 180%; /* 21.6px */
  text-transform: capitalize;
}

/* .loginForm input:is(:focus, :valid) {
  border: 3px solid #1698fc;
  text-align: right;
} */

/* .loginForm input:is(:focus, :valid) + .placeholder {
  transform: translateY(calc(-1 * var(--padding) - 7px)) translateX(-16px);
  font-size: 14px;
  color: #1698fc;
} */

/* CSS برای آیکون */
.loginForm .formIcon {
  position: absolute;
  left: 10px;
  right: 296px;
  top: 50%;
  width: 1.3rem;
  height: 1.3rem;
  transform: translateY(-50%);
}

/* * Switch Style */
.loginForm .loginSwitch {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.loginForm .react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.loginForm .react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 42px;
  height: 20px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.loginForm .react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.loginForm .remember {
  margin-right: 16px;
}

.loginForm
  .react-switch-checkbox:checked
  + .react-switch-label
  .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.loginForm .react-switch-label:active .react-switch-button {
  width: 20px;
}

.loginForm a {
  text-decoration: none;
}

/* * FOOTER * */

.loginForm footer {
  text-align: center;
  margin: 3rem 0;
  /* padding-top: 110px; */
  position: relative;
  bottom: 0;
  width: 100%;
}

.loginForm footer a {
  color: var(--blue-04, #014274);
  text-align: center;
  /* font-family: IRANSansXFaNum; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  text-transform: capitalize;
}
.About-us {
  display: flex;
  gap: 64px;
  /* padding-right: 583px; */
  justify-content: center;
}

.loginForm .footer-item {
  color: var(--67748E, #67748e);
  text-align: center;
  /* font-family: IRANSansXFaNum; */
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 180%; /* 25.2px */
  text-transform: capitalize;
  padding: 0.8rem 0;
}

.loginbutton {
  /* font-family: IRANSansXFaNum; */
  border: 3px solid #1698fc;
}

/* --------------------- */
.input-wrapper {
  --padding: 16px;
  --focus-color: #070707;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem; /* افزودن فضای بین اینپوت‌ها */
}

/* افزودن استایل به لیبل زمانی که ورودی خالی نیست */
.input-wrapper input:not(:placeholder-shown) + .placeholder {
  transform: translateY(calc(-1 * var(--padding) - 7px)) translateX(-16px);
  font-size: 14px;
  color: gray;
}

/* استایل اولیه اینپوت */
.input-wrapper input {
  background-color: #fff;
  width: 308px;
  height: 40px;
  border: 3px solid gray;
  color: #070707;
  /* font-family: IRANSansXFaNum; */
  display: block;
  outline: none;
  border-radius: 10px;
  padding-right: 1rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 180%;
  transition: border 0.3s ease-in-out;
}

/* استایل اینپوت با فوکوس */
.input-wrapper input:focus {
  border: 3px solid var(--focus-color);
}

/* استایل اینپوت با وضعیت خطا */
.input-wrapper.error input {
  border: 3px solid #c30000;
}
/* .input-wrapper.error {
  border: 3px solid #c30000;
} */

/* استایل helper text */
.input-wrapper.error .placeholder {
  color: #c30000 !important;
}
.error-message {
  color: #c30000;
  font-size: 12px;
}

/* استایل پیشنهادی برای فعال بودن اینپوت */
.input-wrapper.active input:focus {
  border: 3px solid #1698fc;

  /* استایل مربوط به وضعیت فعال بودن */
}

/* استایل پیشنهادی برای غیر فعال بودن اینپوت */
.input-wrapper.disabled input {
  border: 3px solid gray;
  /* استایل مربوط به وضعیت غیرفعال بودن */
}

/* استایل اینپوت با وضعیت پیشنهاد خطا یا اخطار */
.input-wrapper.warning input {
  border: 3px solid rgb(234, 164, 0);
  /* استایل مربوط به وضعیت پیشنهاد خطا یا اخطار */
}

/* استایل پیشنهادی برای لیبل */
.placeholder {
  position: absolute;
  background-color: #fff;
  right: var(--padding);
  padding: 0 4px;
  transition: all 0.2s ease-in;
  top: -5;
  color: var(--070707, #070707);
  text-align: right;
  /* font-family: IRANSansXFaNum; */
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 300;
  line-height: 180%;
  text-transform: capitalize;
}

/* استایل لیبل با فوکوس یا وضعیت خطا */
.input-wrapper input:focus + .placeholder,
.input-wrapper.error input + .placeholder {
  transform: translateY(calc(-1 * var(--padding) - 7px)) translateX(-16px);
  /* font-size: 14px; */
  color: #1698fc; /* رنگ مرتبط با فوکوس یا وضعیت خطا */
}

/* استایل لیبل در حالت پیشنهاد خطا یا اخطار */
.input-wrapper.warning input + .placeholder {
  transform: translateY(calc(-1 * var(--padding) - 7px)) translateX(-16px);
  /* font-size: 14px; */
  color: #c30000;
  /* استایل مربوط به وضعیت پیشنهاد خطا یا اخطار */
}

/* استایل آیکون */
.formIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 1.3rem;
  height: 1.3rem;
  transform: translateY(-50%);
  /* استایل مربوط به آیکون */
}

/* .form-background{
  width: 100vw; 
  height: 100vh; 
  background-image: url('../Asset 1 1.svg');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  transform: rotate(20deg); 
  z-index: -1;
} */

.glass-login {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6.8px);
  -webkit-backdrop-filter: blur(6.8px);
  border: 1px solid rgba(255, 255, 255, 0.45);
  width: 45%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 20px;
  gap: 15px;
}
.glass-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-set {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .loginForm .background-image {
    background-image: url("../assets/img/Asset 3 1.svg") !important;
    top: -10% !important;
    /*background-size: contain !important;*/
    background-position: right;
    /*left: 22% !important;*/
    /*transform: rotate(337.4deg) !important;*/
  }
  .loginForm .loginNav {
    width: 95% !important;
  }
  #root {
    overflow: hidden;
  }
  .glass-login {
    width: 80% !important;
  }
  .loginForm h3 {
    font-size: 24px;
  }
  .form-set form {
    width: 80%;
  }

  .loginForm ul {
    flex-direction: column !important;
  }

  .loginForm .loginLogo {
    padding-left: 0px !important;
  }

  .loginForm .loginNav {
    background: rgba(255, 255, 255, 0.5) !important;
  }

  .glass-login {
    background: rgba(255, 255, 255, 0.5) !important;
  }
}

#menuToggle {
  display: block;
  /* position: relative; */
  /* top: 50px; */
  /* left: 50px; */

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
  padding-right: 24px;
  cursor: pointer;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}

#menuToggle input {
  display: none;
  width: 0px;
  height: 0px;
  /* position: absolute;
  top: -7px;
  left: -5px; */

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #1698fc;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #1698fc;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 100%;
  padding: 60px 0px;
  /* padding-top: 125px; */
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  right: -1px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  top: -2px;
  border-radius: 16px;
  border: 1px solid #ededed;
}

#menu li {
  padding: 10px 0;
  /* font-size: 22px; */
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul {
  transform: none;
}
