.container {
  display: flex;
  flex-direction: column;
  width: 97%;
  height: 100%;
  background-color: #f8f9fa;
  gap: 5px;
}

.first-pannel {
  width: 100%;
  height: 78px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}
.second {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 345px;
  background-color: #ffffff;
  border-radius: 15px;
  padding-bottom: 35px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  /* gap: 30px; */
  /* z-index: 1; */
}

.cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.cards-container {
  width: 100%;
  height: 300px;
  /* padding-top: 30px; */
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  scrollbar-width: none;
}

.data-second {
  width: 97%;
  height: 82%;
  position: relative;
  bottom: 65px;
}
.arrow-scroll {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0px 10px;
  position: relative;
  top: 35px;
}
.scroll-button {
  background-color: #ededed;
  color: #000;
}
.third {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  gap: 24px;
  margin-top: 16px;
}
.chrat-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
  width: 45%;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}
.graph {
  width: 33%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}
.sec-plus {
  width: 100%;
  height: 60%;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}
.sec-full {
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
  width: 20%;
}
.report-file {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40%;
  gap: 24px;
}
.pdf-file {
  width: 50%;
  height: 85%;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}

.exel-file {
  width: 50%;
  height: 85%;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
}

.head-store {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 50px;
}

/* .now-store {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  padding: 8px;
} */

.now-store {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  padding: 8px;
  background-color: #f9f9f9;
  border: none;
  cursor: pointer;
  color: #000;
  border: solid 1px #ededed;
}
.icon-arrow {
  color: #014274;
}
/* .now-store p {
  color: #014274;
} */
.curved-corner-topright {
  width: 80px;
  height: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: -30px 30px 8px -16px rgb(248 249 250);
}
.curved-corner-topleft {
  width: 80px;
  height: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 30px 30px 8px -16px rgb(248 249 250);
}
.curved-corner-bottomleft {
  width: 80px;
  height: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 30px -30px 17px -19px rgb(248 249 250);
}
.curved-corner-bottomright {
  width: 80px;
  height: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: -30px -30px 17px -19px rgb(248 249 250);
}

.curved-corner-bottomleft:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 30px;

  bottom: 35px;
  left: 45px;
  /* box-shadow: -50px -50px 0 0 #fff; */
  box-shadow: inset 4px 4px 17px -2px rgba(0, 0, 0, 0.05);
  background-color: #f8f9fa;
}
.curved-corner-bottomright:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 30px;

  bottom: 35px;
  right: 45px;
  /* box-shadow: -50px -50px 0 0 #fff; */
  box-shadow: inset 4px 4px 17px -2px rgba(0, 0, 0, 0.05);
  background-color: #f8f9fa;
}
.curved-corner-topleft:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 30px;

  top: 45px;
  left: 45px;
  /* box-shadow: -50px -50px 0 0 #fff; */
  box-shadow: inset 4px 4px 17px -2px rgba(0, 0, 0, 0.05);
  background-color: #f8f9fa;
}
.curved-corner-topright:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 30px;

  top: 45px;
  right: 45px;
  box-shadow: inset 4px 4px 17px -2px rgba(0, 0, 0, 0.05);
  /* box-shadow: inset 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    inset 0px 10px 15px -3px rgba(96, 97, 98, 0.4); */

  background-color: #f8f9fa;
}
.curved {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 100%;
  /* overflow: hidden; */
  position: relative;
  bottom: 67px;
}
.curved2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 100%;
  /* overflow: hidden; */
  /* position: relative; */
  /* top: 0px; */
  /* bottom: 75px; */
  width: 100%;
}
/* .info-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: auto;
  margin-top: 30px;
} */

.info-second {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-left: auto;
  margin-right: 4%;
  /* margin-bottom: auto; */
  margin-top: 2%;
  /* max-width: 90%;
  min-height: 100px; */
}

.info-second p {
  white-space: nowrap;
}

.report-file {
  display: flex;
  align-items: end;
  justify-content: center;
}
.report-file span {
  text-wrap: nowrap;
  font-size: 12px;
}

.custom-icon-pdf {
  /* color: crimson; */
  width: 90%;
}

.custom-icon-exel {
  /* color: darkgreen; */
  width: 100%;
}
.exel-file,
.pdf-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
}

.textBtn {
  position: relative;
  bottom: 13px;
}
.sec-full {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sec-plus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-right: 5%; */
  /* padding-top: 5%; */
  /* gap: 10px; */
  align-items: center;
  padding-bottom: 8px;
}
.icon-plus {
  color: red;
}
.graph {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: ModamFaNumWeb;
}

.graph-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  width: 90%;
  /* padding-bottom: 7%; */
  padding-top: 8px;
  text-wrap: nowrap;
}

.graph-info p {
  font-size: 12px;
}
.graph-sec {
  width: 100%;
}

.chart-sec {
  width: 100%;
}

.chart-info h6 {
  text-wrap: nowrap;
  padding: 5%;
}
.custom-img-plus {
  position: relative;
  bottom: 30px;
  width: 50%;
}

/* .uploading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
} */
@media (max-width: 768px){
  .head-store{
    padding: 16px 30px;
  }
  .info-second p{
    text-wrap: balance;
  }
  .info-second{
    margin-top: 12%;
  }
  .container{
    width: 95%;
    padding: 8px;
  }
  .cards-container {
    height: 375px ;
    align-items: end ;
    padding-bottom: 16px;
  }
  .second {
    padding: 0 ;
    height: auto ;
  }
  .curved,
  .curved2 {
    display: none ;
  }
  .third{
    flex-direction: column-reverse;
  }
  .chrat-bar,
  .graph,
  .sec-full{
    width: 100%;
  }
}
