.main-layout {
  display: flex;
}
.nav {
  /* font-family: "IRANSansXFaNum-DemiBold"; */
  height: auto;
  width: 100%;
  padding: 0rem 0.75rem;
  margin: 5px 15px 0 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-out;
  /* position: fixed; */
  top: -30px;
  direction: rtl;
  z-index: 2;
}
.nav__items {
  flex-grow: 1;
  list-style-type: none;
  margin-top: 23px;
  margin-right: 16px;
}
.nav__items::-webkit-scrollbar {
  display: none;
}
.nav__item {
  margin: 8px 0;
}

.nav__item-account {
  font-size: 16px;
  color: #222;
}
.nav__logo {
  letter-spacing: 0.2rem;
  font-size: 3rem;
  font-weight: 800;
  color: #fff;
  text-align: center;
  /* border-bottom: 1px solid #ccc; */
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-image: linear-gradient(
      to left,
      rgb(255, 255, 255),
      rgb(0, 217, 255),
      rgba(0, 0, 0, 0)
    )
    100% 1;
}

.frame-56 {
  height: 28px;
  width: 64px;
}

.line-10 {
  width: 183px;
  height: 1px;
}

.nav__link {
  text-decoration: none;
  color: #8392ab;
  display: block;
  padding: 12px 8px;
  transition: all 0.3s ease-out;
  display: flex;
  gap: 8px;
}

.nav__link:hover {
  color: #2c4982;
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
    0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}
.nav__item-label {
  margin: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-container {
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
    0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.nav__icon {
  color: #63bafd;
}

.nav-button {
  background: none;
  border: none;
  bottom: 30px;
  right: 70px;
  position: absolute;
}

.nav-button a {
  color: #014274;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-nav__container {
  background-image: url(../../assets/img/milad-fakurian-Bdg_kKtAKig-unsplash\ 1.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 16px;
  height: 170px;
  position: relative;
  /* bottom: 150px; */
  display: flex;
  flex-direction: column;
  width: 204px;
  margin-top: 120px;
  align-items: center;
  padding: 0px 12px;
  margin-bottom: 10px;
}

.frame-8 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -20px;
  background-color: #ffffff;
  border-radius: 19px;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .nav {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    margin: 0;
    border-radius: 19px 19px 0 0px;
    padding: 0;
    background-color: #fff;
    z-index: 1002;
  }

  .nav__link {
    padding: 5px 5px;
  }
  .nav__items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0;
    padding: 0.5rem 0;
    align-items: center;
  }

  .nav__item {
    margin: 0;
    /* flex: 1; */
    text-align: center;
    position: relative;
  }

  .nav__item-label {
    display: none; /* Hide the label on mobile */
  }

  .icon-container {
    width: 36px; /* Increase the size of the icon container for easier tapping */
    height: 36px;
  }

  .nav__icon {
    font-size: 24px; /* Increase the size of the icons */
  }

  .nav__logo,
  .img-nav__container {
    display: none; /* Hide the logo and help section on mobile */
  }
}
.nav__item.show-label .icon-container {
  background-color: #63bafd;
}
.nav__item.show-label .nav__icon {
  color: #fff;
}
.nav__item.show-label .nav__link {
  color: #2c4982;
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
    0px 4px 6px -1px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}
