.main-detailSection-p-f {
    /* این چهار خط برا تسته جای دیواس*/
    width: 100%;
    height: 180px;
    /* border: 1px solid rgb(190, 190, 190); */
    border-radius: 8px;
    /*---------*/
    margin-bottom: 24px;
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
  }