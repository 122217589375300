.main-detailSection-p-s {
  /* این چهار خط برا تسته جای دیواس*/
  width: 100%;
  height: 180px;
  /* border: 1px solid rgb(190, 190, 190); */
  border-radius: 8px;
  /*---------*/
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.main-detailSection-p-s h6 {
  margin-top: 12px;
}
.week p {
  font-size: 12px;
  line-height: 180%;
  font-style: normal;
  font-weight: 500;
}
.week {
  width: 44px;
  height: 22px;
  background-color: #ededed;
  border-radius: 4px;
  text-align: center;
  margin-top: 8px;
}
.number p {
  font-size: 14px;
  line-height: 180%;
  font-style: normal;
  font-weight: 500;
  color: #67748e;
}
.number {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 16px;
  gap: 4px;
}

.detalSection-input-p {
  width: 100%;
  height: 90%;
  /* background-color: aqua; */
  border-left: 2px solid #f4f4f4;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
}
.icon-input-p {
  background-color: #00966d;
  width: 20px;
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 7px;
  padding: 2.5px;
  box-shadow: 0px 6px 9px rgba(0, 150, 109, 0.5),
    0px 2.5px 4px rgba(0, 0, 0, 0.08);
}
.detalSection-output-p {
  width: 100%;
  height: 90%;
  /* background-color: rgb(255, 0, 0); */
  border-left: 2px solid #f4f4f4;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
}
.icon-output-p {
  background-color: #006fee;
  width: 20px;
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 7px;
  padding: 2.5px;
  box-shadow: 0px 6px 9px rgba(0, 111, 238, 0.5),
    0px 2.5px 4px rgba(0, 0, 0, 0.08);
}

.detalSection-sum-p {
  width: 100%;
  height: 90%;
  /* background-color: rgb(21, 255, 0); */
  border-left: 2px solid #f4f4f4;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
}
.icon-sum-p {
  background-color: #63bafd;
  width: 20px;
  height: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 7px;
  padding: 2.5px 2.5px 2.5px 2px;
  box-shadow: 0px 6px 9px rgba(0, 178, 255, 0.5),
    0px 2.5px 4px rgba(0, 0, 0, 0.08);
}
.detalSection-return-p {
  width: 100%;
  height: 90%;
  /* background-color: rgb(38, 0, 255); */
  border-left: 2px solid #f4f4f4;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
}
.icon-return-p {
  background-color: #fa7100;
  width: 20px;
  height: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 7px;
  padding: 2.5px 2.5px 2.5px 2.5px;
  box-shadow: 0px 6px 9px rgba(248, 76, 11, 0.5),
    0px 2.5px 4px rgba(0, 0, 0, 0.08);
}
.detalSection-date-p h5 {
  margin-top: 25px;
}
.detalSection-date-p {
  width: 100%;
  height: 90%;
  /* background-color: rgb(255, 145, 0); */
  border-left: 2px solid #f4f4f4;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
}

.icon-date-p {
  background-color: #67748e;
  width: 20px;
  height: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 7px;
  padding: 2.5px 2.5px 2.5px 2.5px;
  box-shadow: 0px 6px 9px rgba(103, 116, 142, 0.5),
    0px 2.5px 4px rgba(0, 0, 0, 0.08);
}

@media (max-width: 768px) {
  .main-detailSection-p-s {
    flex-direction: column;
    height: auto;
  }
  .detalSection-date-p,
  .detalSection-return-p,
  .detalSection-sum-p,
  .detalSection-output-p,
  .detalSection-input-p {
    border-left: none;
    border-bottom: 2px solid #f4f4f4;
    margin-bottom: 16px;
  }

}
