.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  color: #f8f9fa;
  padding: 20px;
  width: 97%;
  height: 71px;
}

.menu {
  display: flex;
}

.menu-item {
  margin-left: 20px;
  text-decoration: none;
  color: white;
}

.menu-item:first-child {
  margin-left: 0;
}

.profile-div {
  display: flex;
  align-items: center;
  width: 18%;
  justify-content: space-around;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #006fee;
  border-radius: 8px;
  margin-left: 8px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 112, 243, 0.4);
}
.profile-icon {
  width: 24px;
  height: 24px;
  color: #ffffff;
}

.info {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.des {
  font-size: 12px;
}
.text-root,
.text-header {
  color: #000;
}
.profile-title,
.profile-description {
  color: #000;
  margin: 0;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 112, 243, 0.4);
  border-radius: 8px;
  margin-left: 30px;
  width: 32px;
  height: 32px;
}

.notification-icon {
  width: 24px;
  height: 24px;
  color: #006fee;
}
@media (max-width: 768px){
  .header {
    padding: 16px;
    width: 90%;
  }
  .notification{
    margin: 0;
  }
  .profile-div {
    width: 40%;
  }
}
