@font-face {
  font-family: ModamFaNumWeb;
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/woff2/ModamFaNumWeb-ExtraLight.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("./assets/fonts/woff/ModamFaNumWeb-ExtraLight.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: ModamFaNumWeb;
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/woff2/ModamFaNumWeb-Light.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("./assets/fonts/woff/ModamFaNumWeb-Light.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: ModamFaNumWeb;
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/woff2/ModamFaNumWeb-Regular.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("./assets/fonts/woff/ModamFaNumWeb-Regular.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: ModamFaNumWeb;
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/woff2/ModamFaNumWeb-Medium.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("./assets/fonts/woff/ModamFaNumWeb-Medium.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: ModamFaNumWeb;
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/woff2/ModamFaNumWeb-SemiBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("./assets/fonts/woff/ModamFaNumWeb-SemiBold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: ModamFaNumWeb;
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/woff2/ModamFaNumWeb-Bold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("./assets/fonts/woff/ModamFaNumWeb-Bold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: ModamFaNumWeb;
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/woff2/ModamFaNumWeb-ExtraBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("./assets/fonts/woff/ModamFaNumWeb-ExtraBold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: ModamFaNumWeb;
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/woff2/ModamFaNumWeb-Black.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("./assets/fonts/woff/ModamFaNumWeb-Black.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
