.clock__circle {
    position: relative;
    width: 85px;
    height: 85px;
    background-color: #ECECF3;
    box-shadow: -3px -3px 8px var(--white-color),
      3px 3px 7px hsla(var(--hue-color), 30%, 86%, 1),
      inset 4px 3px 8px hsla(var(--hue-color), 30%, 86%, 1),
      inset -3px -3px 6px var(--white-color);
    border-radius: 50%;
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
  }
  
  /*.alarm-icon {*/
  /*  position: absolute;*/
  /*  font-size: 45px;*/
  /*  left: -25px;*/
  /*  top: -20px;*/
  /*}*/
  
  /*.active {*/
  /*  animation: alarm 2s infinite alternate;*/
  /*}*/
  
  .clock__content {
    align-self: center;
    row-gap: 2rem;
  }
  
  .clock__twelve,
  .clock__three,
  .clock__six,
  .clock__nine {
    position: absolute;
    width: 0.5rem;
    height: 1px;
    background-color: var(--first-color);
  }
  
  .clock__twelve,
  .clock__six {
    transform: translateX(-50%) rotate(90deg);
  }
  
  .clock__twelve {
    top: 0.5rem;
    left: 50%;
    
  }
  
  .clock__three {
    top: 50%;
    right: 0.3rem;
  }
  
  .clock__six {
    bottom: 0.5rem;
    left: 50%;
  }
  
  .clock__nine {
    left: 0.3rem;
    top: 50%;
  }
  
  .clock__rounder {
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--first-color);
    border-radius: 50%;
    border: 2px solid var(--body-color);
    z-index: var(--z-tooltip);
  }
  
  .clock__hour,
  .clock__minutes,
  .clock__seconds {
    position: absolute;
    display: flex;
    justify-content: center;
  }
  
  .clock__hour {
    width: 105px;
    height: 140px;
  }
  
  .clock__hour::before {
    content: "";
    position: absolute;
    background-color: var(--text-color);
    width: 0.1rem;
    height: 40px;
    border-radius: 0.75rem;
    z-index: var(--z-normal);
    top: 2.55rem; /* تغییر موقعیت به بالای مرکز ساعت */

  }
  .clock__minutes {
    width: 105px;
    height: 186px;
  }

  .clock__minutes::before {
    content: "";
    position: absolute;
    background-color: var(--text-color);
    width: 0.1rem;
    height:50px;
    border-radius: 0.75rem;
    z-index: var(--z-normal);
    top: 3.4rem; /* تغییر موقعیت به بالای مرکز ساعت */

  }
  
 
  
  .clock__seconds {
    width: 130px;
    height: 200px;
  }
  
  /* .clock__seconds::before {
    content: "";
    position: absolute;
    background-color: var(--first-color);
    width: 0.125rem;
    height: 7.5rem;
    border-radius: 0.75rem;
    z-index: var(--z-normal);
  }
   */
   .clock__seconds::before {
    content: "";
    position: absolute;
    background-color: var(--first-color);
    width: 0.055rem;
    height: 45px; /* ارتفاع کمتر */
    border-radius: 0.75rem;
    z-index: var(--z-normal);
    top: 3.75rem; /* تغییر موقعیت به بالای مرکز ساعت */
  }
  /*@keyframes alarm {*/
  /*  0%,*/
  /*  100% {*/
  /*    transform: scale3d(1, 1, 1);*/
  /*    text-shadow: 0 0 0 transparent;*/
  /*  }*/
  /*  10%,*/
  /*  20% {*/
  /*    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -6deg);*/
  /*  }*/
  /*  }*/
  /*  30%,*/
  /*  50%,*/
  /*  70%,*/
  /*  90% {*/
  /*    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 6deg);*/
  /*    text-shadow: 0 1px 3px rgb(0 0 0 / 30%);*/
  /*  }*/
  /*  40%,*/
  /*  60%,*/
  /*  80% {*/
  /*    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, -6deg);*/
  /*  }*/
  /*}*/


  :root {
    /*========== Colors ==========*/
    --hue-color: 240;
  
    --first-color: hsl(var(--hue-color), 53%, 49%);
    --title-color: hsl(var(--hue-color), 53%, 15%);
    --text-color: hsl(var(--hue-color), 12%, 35%);
    --text-color-light: hsl(var(--hue-color), 12%, 65%);
    --white-color: #fff;
    --body-color: hsl(var(--hue-color), 24%, 94%);
  
    /*========== Font and typography ==========*/
    --body-font: "Poppins", sans-serif;
    --biggest-font-size: 3rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
    --tiny-font-size: 0.625rem;
  
    /*========== Font weight ==========*/
    --font-medium: 500;
  
    /*========== Margenes Bottom ==========*/
    --mb-0-25: 0.25rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2-5: 2.5rem;
  
    /*========== z index ==========*/
    --z-normal: 1;
    --z-tooltip: 10;
  }