.store-card {
  width: 348px;
  height: 175px;
  background-color: #ffffff;
  border-radius: 10px;
  /* box-shadow: 0px 10px 8px -2px rgba(0, 0, 0, 0.08),
    0px 7px 16px -3px rgba(59, 68, 87, 0.1),
    0px 7px 16px -3px rgba(0, 111, 238, 0.12),
    0px 6px 10px -5px rgba(0, 0, 0, 0.08); */
  box-shadow: 0px 10px 8px -2px rgba(0, 0, 0, 0.08),
    0px 7px 16px -3px rgba(0, 0, 0, 0.1),
    0px 6px 10px -5px rgba(0, 111, 238, 0.12);
  /* 0px 11px 12px 2px rgba(0, 0, 0, 0.05) */
}

.icon-one {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-left: 8px;
  box-shadow: 0px 7px 10px -2px rgba(250, 113, 0, 0.4),
    0px 3px 4px -1.5px rgba(0, 0, 0, 0.05);
}
.icon-filter {
  color: #fa7100;

  text-align: center;
}
.first-sec-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 6%;
  margin-right: 5%;
  align-items: start;
  gap: 10px;
}

.sec-one {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sec-two {
  color: #c2c2c2;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.square {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 190px;
  margin-top: -130px; */
  margin-right: 58%;
  margin-top: -40%;
  width: 100px;
  height: 100px;
  border-radius: 12px;
  background-color: #d9d9d9;
}

.seen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 124px;
  right: -182px;
  /* margin-top: 10%;
  margin-right: -15%; */
  white-space: nowrap;
}

.overlay {
  /* text-align: center; */
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  width: 130px;
  height: 36px;
  background-color: rgb(0, 0, 0);
  color: #ffffff;
  border-radius: 12px;
  display: none;
  animation: slide-up 0.3s ease forwards;
  transition: top 0.3s ease;
  display: none;
}
.overlay p {
  font-size: 14px;
  height: 100%;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.store-card:hover .overlay {
  /* top: -75px; */
  display: block;
}
.store-card:hover .square {
  background-color: #cbcbcb;
}
.store-card:hover {
  border: solid 1px #ededed;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.08),
    0px 1px 16px 0px rgba(59, 68, 87, 0.1),
    0px 1px 10px 0px rgba(0, 111, 238, 0.12),
    0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

@keyframes slide-up {
  from {
    top: -55px; /* موقعیت اولیه */
  }
  to {
    top: -75px; /* موقعیت نهایی */
  }
}

@media (max-width: 768px) {
  .square {
    margin-top: 0;
    margin-right: 8%;
  }
  .store-card {
    width: 150px;
    height: 290px;
  }
  
  @keyframes slide-up {
    from {
      top: -55px; /* موقعیت اولیه */
    }
    to {
      top: -50px; /* موقعیت نهایی */
    }
  }
  
  .seen{
    right: -195px;
  }
}
